.center {
  padding: 20px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  .row1 {
    display: flex;
    justify-content: space-between;
    .row1item {
      background-color: #fff;
      box-sizing: border-box;
      height: 150px;
      width: 49%;
      border-radius: 10px;
      padding: 15px;
      .title {
        font-size: 18px;
        font-weight: bold;
      }
      .subtitle {
        font-size: 14px;
        // font-weight: bold;
        color: #bbb;
      }
      .money {
        font-size: 18px;
        color: black;
        font-weight: bold;
      }
      .itemul {
        width: 70%;
        padding: 0;
        margin: 0;
        display: flex;
        list-style-type: none;
        justify-content: space-around;
        .itemli {
          display: flex;
          align-items: center;
          .bigfont {
            color: orange;
            font-size: 40px;
            margin-right: 15px;
          }
        }
      }
    }
  }
  .row2 {
    margin-top: 20px;
    background-color: #fff;
    box-sizing: border-box;
    height: 150px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    .headerwrap {
      display: flex;
      justify-content: space-between;
      .row2header {
        height: 35px;
        width: 180px;
        background-color: #3e8ef7;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
    }
    .row2ul {
      margin-top: 15px;
      list-style-type: none;
      display: flex;
      justify-content: space-around;
      .row2p {
        font-size: 16px;
        font-weight: bold;
      }
      .row2div {
        font-size: 16px;
        font-weight: bold;
        display: flex;
      }
    }
  }
  .row3 {
    margin-top: 20px;
    background-color: #fff;
    box-sizing: border-box;
    height: 400px;
    width: 100%;
    border-radius: 10px;
    padding: 15px;
    display: flex;
    .row3yj {
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      text-align: left;
      .row3header {
        height: 35px;
        width: 120px;
        background-color: #3e8ef7;
        border-radius: 10px;
        line-height: 34px;
        text-align: center;
        font-size: 16px;
        color: #fff;
        font-weight: bold;
      }
      .row3ul {
        margin-top: 15px;
        list-style-type: none;
        .row3p {
          font-size: 16px;
          font-weight: bold;
        }
      }
    }
  }
}
#mytable {
  .ant-table {
    margin-left: 100px;
    width: 800px;
    height: 200px !important;
    overflow-y: hidden!important;
    box-sizing: border-box!important;
    .ant-table-body {
      height: 150px;
    }
  }
}
#confirmtable {
  .ant-table {
    margin-left: 100px;
    width: 800px;
    height: 200px !important;
    overflow-y: hidden!important;
    box-sizing: border-box!important;
    .ant-table-body {
      height: 150px;
    }
  }
}
#chgtable {
  .ant-table {
    margin-left: 100px;
    width: 800px;
    height: 200px !important;
    overflow-y: hidden!important;
    box-sizing: border-box!important;
    .ant-table-body {
      height: 150px;
    }
  }
}
#sharelogtable {
  .ant-table {
    width: 900px;
    height: 200px !important;
    overflow-y: hidden!important;
    box-sizing: border-box!important;
    .ant-table-body {
      height: 150px;
      margin-right: 15px;
    }
  }
}

.quotetable {
  :global {
    .ant-table {
      height: 233px !important;
      // overflow-y: auto!important;
      box-sizing: border-box!important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-tbody > tr > td {
      height: 40px;
      padding: 0;
      box-sizing: border-box;
    }
  }
}
.gpbalancetable {
  width: 100%;
  display:flex;
  justify-content:center;
  :global {
    .ant-table {
      height: auto !important;
      // overflow-y: hidden!important;
      // box-sizing: border-box!important;
    }
    .ant-table-body {
      height: auto !important;
    }
  }
}
.accoutbooktable {
  :global {
    .ant-table {
      height: calc(100vh - 300px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 8px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 270px) !important;
      // height: 58.2vh;
    }
    /* .ant-table-tbody{
      .ant-table-row {
        .ant-table-cell-row-hover {
          background-color: rgb(217, 236, 243);
        }
      }
    } */
  }
}
.listdiv {
  margin-right: 20px;
  height: calc(100vh - 230px);
  overflow-y: auto;
}
.movebar {
  width: 5px;
  height: calc(100vh - 200px);
  background-color: rgb(182, 184, 185);
  color: rgb(182, 184, 185);
  cursor: e-resize;
  user-select: none;
}
.subjectlist {
  min-width: 100px;
  height: calc(100vh - 200px);
  border: 1px solid #ccc;
  position: relative;
  :global {
    .ant-tabs-top {
      .ant-tabs-nav {
        margin: 0 !important;
      }
    }
    .ant-tabs-nav-wrap {
      background-color: #C0E4FF !important;
      .ant-tabs-nav-list {
        height: 30px;
        background-color: #C0E4FF !important;
        .ant-tabs-tab {
          font-size: 12px;
          border-color: transparent !important;
          padding: 0 10px !important;
          background-color: #C0E4FF !important;
        }
        .ant-tabs-tab-active {
          background-color: #fff !important;
        }
        .ant-tabs-tab:hover {
          background-color: #eee !important;
        }
      }
    }
  }
}
.height100 {
  display: flex;
}
.detailtable {
  :global {
    .ant-table {
      width: '100%';
      height: calc(100vh - 200px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 8px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 235px) !important;
      // height: 58.2vh;
    }
  }
}
//1个搜索栏+两个表头+分页 - 295
.height200 {
  :global {
    .ant-table {
      height: calc(100vh - 228px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 290px) !important;
      // height: 58.2vh;
    }
  }
}
//2个搜索栏+功能栏+分页 - 340
.height280 {
  :global {
    .ant-table {
      height: calc(100vh - 305px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 332px) !important;
      // height: 58.2vh;
    }
  }
}
//一个搜索栏+功能栏+分页 - 300
.height270 {
  :global {
    .ant-table {
      height: calc(100vh - 265px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 302px) !important;
      // height: 58.2vh;
    }
  }
}
//一个搜索栏+分页 - 260
.height260 {
  :global {
    .ant-table {
      height: calc(100vh - 225px) !important;
      // height: 63.5vh !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 262px) !important;
      // height: 58.2vh;
    }
  }
}
//一个搜索栏 - 240
.height240 {
  :global {
    .ant-table {
      height: calc(100vh - 205px) !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 240px) !important;
    }
  }
}
//2个搜索栏+2个表头+分页 - 330
.height230 {
  :global {
    .ant-table {
      height: calc(100vh - 255px) !important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-body {
      height: calc(100vh - 320px) !important;
    }
  }
}
.default12 {
  :global {
    .ant-table {
      height: auto !important;
    }
    .ant-table-body {
      height: auto;
    }
  }
}
.filewrap {
  display: flex;
  :global {
    .ant-upload-list-item {
      margin-top: 1px !important;
    }
  }
}
.filewrap>span {
  display: flex !important;
}
.cancel {
  position:absolute;
  transform: rotate(-45deg);
  z-index: 999;
  top: 200px;
  right: 0;
}
.buttonwrap {
  height: 50px;
  width: 49px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  .adddel {
    color: rgb(109, 72, 3);
    font-size: 18px;
    box-sizing: border-box;
  }
  .adddel:hover {
    color: rgb(86, 190, 231);
    box-sizing: border-box;
  }
}
.table {
  // height:'64vh';
  min-width: 1300px;
  overflow-x: auto;
  :global {
    .ant-table {
      height: auto !important;
      overflow-y: auto!important;
      box-sizing: border-box!important;
    }
    .ant-table-thead > tr > th {
      padding: 5px;
      box-sizing: border-box;
    }
    .ant-table-tbody {
      > tr:hover:not(.ant-table-expanded-row) > td,.ant-table-row-hover,.ant-table-row-hover>td{
      //  background:#E5F2FF!important;
       background:rgb(217, 236, 243)!important;
      }
    }
    .ant-table-fixed {
      .ant-table-row-hover, .ant-table-row-hover>td{
        // background: #E5F2FF!important;
        background: rgb(217, 236, 243)!important;
      }
    }
  }
}
.login {
  margin: 0;
  padding: 0;
  width: 100vw;
  height: 100vh;
  margin:0px;
  background: url(../materials/img/loginbg.png) no-repeat;
  background-size:100% 100%;
  background-attachment:fixed;
  display: flex;
  justify-content: center;
  align-items: center;
  .logowrap {
    width: 65vw;
    height: 656px;
    border-radius: 16px;
    box-shadow: 0 0 10px 5px rgba(0, 0, 0, 0.2);
    display: flex;
    .left {
      width: 717px;
      height: 656px;
      background: url(../materials/img/loginleftbg.png) 100% 100%;
    }
    .right {
      flex: 1;
      padding: 20px;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      /* form表单 */
      .loginform {
        width: 100%;
        // background-color: pink;
        height: 50vh;
        max-width: 20vw;
        .header {
          width: 100%;
          font-size: 20px;
          font-weight: bold;
          color: #fff;
        }
        .footer {
          color: #fff;
        }
        .footer:hover {
          color: rgb(128, 231, 245);
        }
      }
    }
  }
}
.mycheckbox {
  color: #fff;
  :global {
    .ant-checkbox-checked {
      .ant-checkbox-inner {
        background-color: #4eaeff !important;
        border-color: #4eaeff !important;
      }
    }
  }
}
.loginbutton {
  :global {
    .ant-btn-primary{
      border-color: #4eaeff !important;
      background: #4eaeff !important;
    }
  }
}
.loginbutton:hover {
  :global {
    .ant-btn-primary{
      border-color: #1890ff !important;
      background: #1890ff !important;
    }
  }
}


.container {
  & :global .ant-modal-body {
    max-height: 700px;
    overflow: auto;
  }
}
.fixheight {
  & :global .ant-modal-body {
    height: 480px;
    overflow: auto;
  }
}
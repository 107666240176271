.header {
  min-width: 100vw;
  height: 6vh;
  background-color: #3fa3f1;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.sidebar {
  overflow: auto;
  position: fixed;
  top: 6vh;
  left: 0;
  bottom: 0;
  .menu {
    min-height: 94vh;
  }
}

.sidebar::-webkit-scrollbar {display: none}

.content {
  background-color: #f4f4f4;
  // min-height: 94vh;
  // height: auto;
  height: 94vh;
  margin-left: 210px;
  box-sizing: border-box;
}
.undername {
  text-align: center;
}
.undername:hover {
  cursor: pointer;
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body::-webkit-scrollbar {display: none}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
th.ant-table-cell {
  background-color: skyblue !important;
}
td.ant-table-column-sort {
  background: none !important;
}
// .ant-table-tbody > tr > td {
//   border-bottom: 1px solid #818080 !important;
// }
.ant-table-tbody > tr > td.ant-table-cell-row-hover {
  background: none !important;
}
.ant-table-column-sorter {
  color:black !important;
}
.ant-table-column-sorter:hover {
  color:none !important;
}
.accountbookTable {
  .ant-table-tbody {
    .ant-checkbox-wrapper {
      position: absolute !important;
      top: 18px !important;
      left: 18px !important;
    }
  }
}
.ant-tabs-top {
  height: 100% !important;
  .ant-tabs-tabpane {
    height: 100% !important;
  }
}
.ant-tabs-top > .ant-tabs-nav {
  margin: 0 !important;
}
.ant-tabs-content {
  height: 100% !important;
}
// .ant-table {
//   // height: 63.5vh !important;
//   overflow-y: hidden!important;
//   box-sizing: border-box!important;
// }
// .ant-table-wrapper {
//   max-width: none;
// }
.ant-spin-nested-loading {
  height: 100%;
  .ant-spin-container {
    height: 100%;
    .ant-table-small {
      height: 100%;
      .ant-table-container {
        height: 100%;
        .ant-table-body {
          height: calc(100% - 38px);
          overflow: auto !important;
        }
      }
    }
  }
}
/* .ant-table-thead > tr > th {
  padding: 5px !important;
  box-sizing: border-box !important;
}
.ant-table-tbody > tr > td {
  height: 40px !important;
  padding: 5px !important;
  box-sizing: border-box !important;
} */
// .ant-table-body {
//   height: 58.2vh;
// }
.ant-form-item {
  height: 60px;
  margin-bottom: 0!important;
}
/* .ant-table-expanded-row-fixed {
  height: 400px;
} */
// @media print {
//   body * {
//     visibility: hidden;
//   }
//   #tabletest {
//     position: absolute;
//     left: 0;
//     top: 0;
//   }
// }

.printtable {
  width: 100%;
  // margin: 10px;
  // border: 1px solid #000;
  border-collapse: collapse;
  font-size: 12;
  // margin: 5px auto
  th {
    text-align: center
  }
  th, td {
    box-sizing: border-box;
    border: 1px solid #000;
    border-collapse: collapse;
    padding: 1px;
    page-break-inside: avoid;
  }
  // thead {
  //   border: none;
  // }
  // thead tr {
  //   border: none;
  // }
  // thead tr th {
  //   border: none;
  //   border-collapse: separate;
  // }
  
  h1 {
    text-align: center;
    font-size: 24px
  }
}
.site-input-split {
  background-color: #fff !important;
  width: 30px;
  border-left: 0;
  border-right: 0;
  pointer-events: 'none'
}
.site-input-left {
  border-right-width: 0;
  width: 100px;
  text-align: 'center'
}
.site-input-right {
  border-left-width: 0;
  width: 100px;
  text-align: 'center'
}

.site-input-left:hover,.site-input-left:focus,.site-input-right:hover,.site-input-right:focus {
  border-left-width: 1px;
}